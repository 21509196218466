.header_container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header_text_div {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.header_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1;
}

.header_description {
  font-family: Proxima;
  font-size: 1.3rem;
  text-align: justify;
}

.header_img_div {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5%;
}

.dp_img {
  width: 28rem;
  object-fit: contain;
}

.header_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2%;
}

.header_btn {
  font-family: Proxima;
  font-size: 1.3rem;
  text-align: justify;
  text-transform: uppercase;
}

.header_btn_div {
  text-align: center;
  padding: 20px;
  width: 240px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.header_btn_div span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.header_btn_div span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.header_btn_div:hover span {
  padding-right: 20px;
}

.header_btn_div:hover span:after {
  opacity: 1;
  right: 0;
}

@media only screen and (max-width: 450px) {
  .header_title {
    font-size: 4rem;
    margin-top: 10%;
  }

  .header_description {
    font-size: 1.2rem;
    text-align: left;
    width: 89vw !important;
  }

  .header_img_div {
    width: 10%;
    margin-top: -5%;
    position: absolute;
    top: 116%;
    left: 46%;
  }

  .header_text_div {
    width: 89%;
    margin-top: 0%;
  }

  .dp_img {
    width: 18rem;
    object-fit: contain;
  }

  .header_btn_container {
    margin-top: 90%;
    display: flex;
    flex-direction: column;
  }

  .header_btn_div {
    width: 90vw;
    margin-bottom: 2%;
  }
}
