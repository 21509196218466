.blog_container{
    width:40%;
    margin:1% 3%;
    cursor: pointer;
    padding: 2%;
    transition: all 0.2s;
    border: 2px solid #FFCA2C;
}

.blog_container:hover{
    box-shadow: 5px 5px 10px #cccccc,
    -5px -5px 10px #ffffff;
    border: none;
    transition: all 0.2s;
}

.blog_img{
    width:100%;
    height:auto;
    object-fit: contain;
}

.blog_img_div{
    border: 8px solid #FFCA2C;
    border-radius:10px;
}

.blog_title{
    margin: 4% 0%;
    font-family: Proxima;
    font-weight: 400;
    font-size:2rem;
    min-height:6rem;
}

@media only screen and (max-width: 450px) {
    .blog_container{
        width:94%;
        border-radius: 10px;
        margin-bottom: 5%;
    }

    .blog_img_div{
        border: 5px solid #FFCA2C;
    }

    .blog_title{
        margin: 4% 0%;
        font-size:1.6rem;
        min-height:3rem;
    }
}