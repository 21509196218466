.App {
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 100%;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
