.blogs_container {
  padding: 2%;
}

.blogs_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 3rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
  letter-spacing: 2px;
}

.blogs_title > div {
  background-color: black;
  width: 4rem;
  height: 2rem;
  margin-right: 1%;
}

.blogs_cards_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
}

@media only screen and (max-width: 450px) {
  .blogs_title > div {
    width: 2rem;
    height: 2rem;
    margin: 0% 3%;
  }

  .blogs_title {
    font-size: 2.2rem;
    margin-bottom: 3%;
  }

  .blogs_cards_div {
    flex-direction: column;
    width: 100%;
  }
}