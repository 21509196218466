.contact_container {
  padding: 2%;
  margin-bottom: 4%;
}

.contact_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 3rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
  letter-spacing: 2px;
}

.contact_title > div {
  background-color: black;
  width: 4rem;
  height: 2rem;
  margin-right: 1%;
}

.contact_div {
  font-family: Proxima;
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.contact_info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1% 3%;
}

.contact_icon_img{
  height: 3rem;
}

.contact_icon_div{
  margin-right: 3%;
}

.contact_info_title{
  font-size: 1.8rem;
  white-space: nowrap;
}

@media only screen and (max-width: 450px) {
  .contact_title > div {
    width: 2rem;
    height: 2rem;
    margin: 0% 3%;
  }

  .contact_title {
    font-size: 2.2rem;
    margin-bottom: 3%;
  }

  .contact_info {
    padding: 1% 3%;
    margin-left: 3%;
  }

  .contact_info_title{
    font-size: 1.4rem;
  }
}
