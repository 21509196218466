.about_container {
  padding: 2%;
}

.about_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 3rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
  letter-spacing: 2px;
}

.about_title > div {
  background-color: black;
  width: 4rem;
  height: 2rem;
  margin-right: 1%;
}

.about_info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.about_timeline {
  width: 80%;
  margin-left: -30%;
}

.about_icons {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about_icons_div {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_icons_img {
  height: 5rem;
  object-fit: contain;
}

@media only screen and (max-width: 450px) {
  .about_title > div {
    width: 2rem;
    height: 2rem;
    margin: 0% 3%;
  }

  .about_title {
    font-size: 2.2rem;
    margin-bottom: 3%;
  }

  .about_icons {
    display: none;
  }

  .about_timeline {
    width: 100vw;
    margin-left: 0%;
  }

  .timeline_title {
    font-size: 1rem;
  }

  .css-1fe7e6p-MuiTimelineSeparator-root {
    margin-left: -85%;
  }
}
