.projects_container {
  padding: 2%;
}

.projects_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 3rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
  letter-spacing: 2px;
}

.projects_title > div {
  background-color: black;
  width: 4rem;
  height: 2rem;
  margin-right: 1%;
}

.projects_github_profile > button {
  width: 90% !important;
  margin-left: 5% !important;
}

@media only screen and (max-width: 450px) {
  .projects_title > div {
    width: 2rem;
    height: 2rem;
    margin: 0% 3%;
  }

  .projects_title {
    font-size: 2.2rem;
    margin-bottom: 3%;
  }

  .projects_github_profile > button {
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 10%;
  }
}