.project_container {
  border-radius: 14px;
  background: #ffffff;
  border: 2px solid #ffca2c;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  transition: all 0.2s;
}

.project_container:hover {
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
  border: none;
  transition: all 0.2s;
}

.project_img_div {
  padding: 1%;
  margin: 2% 0%;
  cursor: pointer;
}

.project_img {
  object-fit: contain;
  width: 22rem;
  border-radius: 14px;
  border: 8px solid #ffca2c;
}

.project_info {
  width: 100%;
  margin: 0% 1%;
  display: flex;
  flex-direction: column;
}

.project_title {
  font-family: Proxima;
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: 1px;
  margin: 1% 0%;
  width: 100%;
  height: 25%;
  border-bottom: 2px solid #ffca2c;
}

.project_description {
  max-width: 98%;
  height: 45%;
  margin: 1% 0%;
  text-align: justify;
}

.project_link_div {
  width: 100%;
  height: 15%;
  margin: 1% 0%;
}

.project_link_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.project_link_div > button {
  background-color: #4094f8;
  margin-right: 5%;
  height: 2.5rem;
  font-family: Proxima;
  font-size: 1.2rem;
  letter-spacing: 1px;
  width: 5rem;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(darken(dodgerblue, 40%));
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 4px solid lighten(gray, 70%);
}
.project_link_div > button:hover {
  box-shadow: 0px 15px 25px -5px rgba(darken(dodgerblue, 40%));
  transform: scale(1.03);
}
.project_link_div > button:active {
  box-shadow: 0px 4px 8px rgba(darken(dodgerblue, 30%));
  transform: scale(0.98);
}

@media only screen and (max-width: 450px) {
  .project_container {
    flex-direction: column;
    border: none;
    width: 99%;
  }

  .project_img {
    object-fit: contain;
    width: 100%;
    border-radius: 14px;
    border: 4px solid #ffca2c;
  }

  .project_title {
    font-size: 1.8rem;
    width: 98%;
    margin-bottom: 4%;
  }

  .project_link_div {
    margin: 5% 2%;
  }

  .project_link_div > button {
    color: white;
  }
}
